
import { defineComponent, watch } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    // 实例化路由
    const router = useRouter()
    
    // 路由变化时，页面置顶
    watch(router.currentRoute, () => {
      window.scrollTo(0, 0)
    })
  }
})
