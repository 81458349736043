import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../public/static/icon/iconfont.css'
import IconSvg from './components/IconSvg.vue'

createApp(App).component('icon-svg', IconSvg).use(store).use(router).mount('#app')

// 批量注册svg -- 会找到./assets/svg路径下的所有svg进行批量注册
const req = require.context('./assets/svg', false, /\.svg$/)
let svgs: any = []
req.keys().forEach(key => {
  const file = req(key)
  svgs = svgs.concat(file)
})
