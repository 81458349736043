import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import('@/views/Solution/index.vue')
  },
  {
    path: '/solution-detail',
    name: 'SolutionDetail',
    component: () => import('@/views/Solution/detail.vue')
  },
  {
    path: '/hardware',
    name: 'Hardware',
    component: () => import('@/views/Hardware/index.vue')
  },
  {
    path: '/hardware-detail',
    name: 'HardwareDetail',
    component: () => import('@/views/Hardware/detail.vue')
  },
  {
    path: '/software',
    name: 'Software',
    component: () => import('@/views/Software/index.vue')
  },
  {
    path: '/software-detail',
    name: 'SoftwareDetail',
    component: () => import('@/views/Software/detail.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
